@use './shared.scss';

$line: 4px;
$timeline-margin: 20%;

.section {
    margin-top: shared.$spacing-vertical;

    @media only screen and (max-width: shared.$tablet-size) {
        & {
            margin-top: 20px;
        }

        &~.section {
            margin-top: shared.$spacing-vertical;
        }
    }
}

.section h2 {
    margin: 0 0 10px $timeline-margin;
    padding: 0 shared.$spacing-horizontal;
}

.timeline {
    border-left: $line solid shared.$color-primary;
    border-bottom-right-radius: shared.$radius;
    border-top-right-radius: shared.$radius;
    margin-left: $timeline-margin;
    letter-spacing: 0.5px;
    position: relative;
    line-height: 1.8em;
    font-size: 1.03em;
    padding: 20px shared.$spacing-horizontal;
    list-style: none;
    text-align: left;
    font-weight: 100;
    max-width: 70%;

    @media only screen and (max-width: shared.$tablet-size) {
        padding-right: 0;
    }

}