@use './shared.scss';

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 1em;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: sub;
}


body {
  background-color: shared.$background;
  transition: background-color 0.5s ease;
  color: shared.$color-font;
  font-family: shared.$font-text;
  margin: 0;

  &.home {
    background-color: shared.$background;
  }

  &.projects {
    background-color: #f9fbe4;
  }

  &.education {
    background-color: #fff3e0;
  }

  &.career {
    background-color: #eaecf4;
  }

  &.projectsHover {
    background-color: #f9fbe4;
  }

  &.educationHover {
    background-color: #fff3e0;
  }

  &.careerHover {
    background-color: #eaecf4;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: shared.$font-title;
}

a {
  color: inherit;
  text-decoration: none;
}

.header {
  text-align: center;
  font-size: 1.2em;
  margin-top: 5px;

}

.inline-logo {
  display: inline-block;
  width: 1em;
  height: 1em;
}

.content {
  margin: 5px;
  display: grid;
  grid-auto-flow: column;

  @media only screen and (max-width: shared.$tablet-size) {
    grid-auto-flow: row;
  }

  grid-auto-columns: 1fr;
  align-items: stretch;
}

.column {
  color: shared.$color-font;
  margin: 10px;

  @media only screen and (min-width: shared.$tablet-size) {
    max-height: 85vh;
  }
}

.scrollable {
  @media only screen and (min-width: shared.$tablet-size) {
    overflow: auto;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }
  }
}

footer {
  text-align: center;
  margin-bottom: 5px;

  // vertical-align: bottom;
  @media only screen and (min-width: shared.$tablet-size) {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}