$background: #FFFEF3;

$color-primary: #04bfbf;
$color-light: #AAAAAA;
$color-dark: #555555;
$color-font: #222222;

$spacing-horizontal: 30px;
$spacing-vertical: 100px;
$radius: 4px;

$side-margin: 10%;

$tablet-size: 1100px;



@font-face {
    font-family: 'junge';
    src: url('fonts/Junge/Junge-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'ysabeau';
    src: url('fonts/Ysabeau/Ysabeau-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    src: url('fonts/Material\ Symbols/MaterialSymbolsOutlined.woff2') format('woff');
}


$font-title: 'junge', serif;
$font-text: 'ysabeau', sans-serif;