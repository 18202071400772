@use './shared.scss';

.main {
    @media only screen and (min-width: shared.$tablet-size) {
        margin-right: shared.$side-margin;
    }


    display: flex;
    flex-direction: column;
}