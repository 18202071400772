@use './shared.scss';
@use './Section.module.scss';

$date: 140px;
$dotborder: 4px;
$dot: 11px;

.item {
    padding-bottom: (shared.$spacing-vertical * 0.5);
    margin: 0;
    position: relative;
    cursor: pointer;

    @media only screen and (min-width: shared.$tablet-size) {
        &:hover {
            font-weight: 600;
            font-size: 1.4rem;
            line-height: 1.9rem;
        }
    }

    h3 {
        letter-spacing: 1.5px;
        font-weight: 400;
        font-size: 1.4em;
        margin: 0;
    }

    p {
        margin: 0;
    }

    &:last-of-type {
        padding-bottom: 0;
        margin-bottom: 0;
        border: none;
    }

    &:before,
    &:after {
        position: absolute;
        display: block;
        top: 0;
    }

    &:before {
        left: ($date + shared.$spacing-horizontal * 2 + Section.$line + ($dot * 0.35)+55) * -1;
        content: attr(data-date);
        text-align: right;
        font-weight: 100;
        font-size: 1.4rem;
        min-width: 200px;
        font-family: shared.$font-title;
        white-space: pre-wrap;
    }

    &:after {
        box-shadow: 0 0 0 $dotborder shared.$color-primary;
        left: (shared.$spacing-horizontal + Section.$line + ($dot * 0.35)) * -1;
        background: lighten(shared.$background, 5%);
        border-radius: 50%;
        height: $dot;
        width: $dot;
        content: "";
        top: 0.5rem;
    }
}