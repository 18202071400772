@use './shared.scss';

.preview {
    position: relative;

    & img {
        width: 100%;
    }

    margin-left: 20px;
    margin-right: 20px;

    @media only screen and (min-width: shared.$tablet-size) {
        margin-top: shared.$spacing-vertical + 50px;
        margin-right: 10%;
        margin-left: shared.$side-margin;

        & img {
            width: 80%;
        }
    }

    &>div {
        text-align: justify;

        @media only screen and (min-width: shared.$tablet-size) {
            text-align-last: right;

            & ul {
                direction: rtl;
            }
        }

        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
            margin-bottom: 0.2em;
            text-align: left;

            @media only screen and (min-width: shared.$tablet-size) {
                text-align: right;
            }
        }

        & p {
            margin-top: 0;
        }
    }
}